import Vue from "vue";
import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;
Vue.prototype.$pages = [
  { number: 1, panel1: true, panel2: true, panel3: true },
  { number: 2, panel1: true, panel2: true, panel3: true },
  { number: 3, panel1: true, panel2: true, panel3: true },
  { number: 4, panel1: true, panel2: true, panel3: true },
  { number: 5, panel1: true, panel2: true, panel3: true },
  { number: 6, panel1: true, panel2: true, panel3: true },
  { number: 7, panel1: true, panel2: true, panel3: true },
  { number: 8, panel1: true, panel2: true, panel3: true },
  { number: 9, panel1: true, panel2: true, panel3: true },
  { number: 10, panel1: true, panel2: true, panel3: true },
  { number: 11, panel1: true, panel2: true, panel3: true },
  { number: 12, panel1: true, panel2: true, panel3: true },
  { number: 13, panel1: true, panel2: true, panel3: true },
  { number: 14, panel1: true, panel2: true, panel3: true },
  { number: 15, panel1: true, panel2: true, panel3: true },
  { number: 16, panel1: true, panel2: true, panel3: true },
  { number: 17, panel1: true, panel2: true, panel3: true },
  { number: 18, panel1: true, panel2: true, panel3: true },
  { number: 19, panel1: true, panel2: true, panel3: true },
  { number: 20, panel1: true, panel2: true, panel3: true },
  { number: 21, panel1: true, panel2: true, panel3: true },
  { number: 22, panel1: true, panel2: true, panel3: true },
  { number: 23, panel1: true, panel2: true, panel3: true },
  { number: 24, panel1: true, panel2: true, panel3: true },
  { number: 25, panel1: true, panel2: true, panel3: true },
  { number: 26, panel1: true, panel2: true, panel3: true },
  { number: 27, panel1: true, panel2: true, panel3: true },
  { number: 28, panel1: true, panel2: true, panel3: true },
  { number: 29, panel1: true, panel2: true, panel3: true },
  { number: 30, panel1: true, panel2: true, panel3: true },
  { number: 31, panel1: true, panel2: true, panel3: true },
];

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
